// **************LocalHost******************

// export const domain = 'http://localhost:4000';
// export const wssdomain = 'ws://localhost:4000';


// **************Production******************

export const domain = 'https://demo5.anotechgaming.com';
export const wssdomain = 'ws://demo5.anotechgaming.com';

// **************Production2******************

// export const domain = 'https://anotechsolutions.in';
// export const wssdomain = 'wss://anotechsolutions.in';